import { defineStore } from "pinia";
import { Program } from "@/types/dashboard/types";
import { UserProgramProfile } from "@/types/dashboard/types";

interface expertProgramsState {
  programs: Program[];
  selectedProgram: Program | null;
  userProgramProfiles: UserProgramProfile[];
}

export const useExpertProgramsStore = defineStore("expertPrograms", {
  state: (): expertProgramsState => ({
    programs: [],
    selectedProgram: null,
    userProgramProfiles: [],
  }),
  actions: {
    setPrograms(newPrograms: Program[]) {
      this.programs = newPrograms;
    },
    addProgram(newProgram: Program) {
      this.programs = [...this.programs, newProgram];
    },
    setSelectedProgram(newSelectedProgram: Program) {
      this.selectedProgram = newSelectedProgram;
    },
    setProgramProfiles(newUserProgramProfiles: UserProgramProfile[]) {
      this.userProgramProfiles = newUserProgramProfiles;
    },
    addProgramProfiles(newProfiles: UserProgramProfile | UserProgramProfile[]) {
      if (Array.isArray(newProfiles)) {
        this.userProgramProfiles = [
          ...this.userProgramProfiles,
          ...newProfiles,
        ];
      } else {
        this.userProgramProfiles = [...this.userProgramProfiles, newProfiles];
      }
    },
    updateProgramProfile(updatedProfile: UserProgramProfile) {
      const index = this.userProgramProfiles.findIndex(
        (profile) => profile.id === updatedProfile.id
      );
      if (index !== -1) {
        this.userProgramProfiles[index] = updatedProfile;
      }
    },
    clearStore() {
      this.selectedProgram = null;
      this.userProgramProfiles = [];
      this.programs = [];
    },
  },
  persist: true,
});
