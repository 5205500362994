<template>
  <nav
    class="flex flex-wrap gap-5 justify-between px-7 py-3 w-full bg-black bg-opacity-40 max-md:px-5 max-md:max-w-full"
  >
    <div
      class="flex flex-wrap gap-10 text-sm font-medium leading-none text-gray-600 max-md:max-w-full"
    >
      <img
        loading="lazy"
        src="../../assets/onboarding/company-logo.png"
        alt="Company logo"
        class="object-contain shrink-0 my-auto aspect-[4.05]"
      />
      <div class="flex flex-wrap gap-5">
        <div
          class="flex overflow-hidden items-center py-2 pr-3 pl-2 whitespace-nowrap rounded-md"
        >
          <div class="flex gap-3 items-center self-stretch my-auto">
            <div v-html="HOME_ICON" class="text-indigo-500"></div>
            <span class="self-stretch my-auto">Dashboard</span>
          </div>
        </div>
        <div
          class="flex overflow-hidden items-center py-2 pr-3 pl-2 rounded-md"
        >
          <div class="flex gap-3 items-center self-stretch my-auto">
            <div v-html="HOME_ICON" class="text-indigo-500"></div>
            <span class="self-stretch my-auto">My Expert Programs</span>
          </div>
        </div>
        <div
          class="flex overflow-hidden items-center py-2 pr-3 pl-2 whitespace-nowrap rounded-md"
        >
          <div class="flex gap-3 items-center self-stretch my-auto">
            <div v-html="MUSICAL_NOTES_ICON" class="text-indigo-500"></div>
            <span class="self-stretch my-auto">Earnings</span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex gap-4 self-start mt-1">
      <div class="shrink-0 my-auto w-px h-8 bg-gray-500 bg-opacity-25"></div>
      <div class="flex">
        <div class="flex z-10 gap-3 items-center mr-0 font-medium">
          <div
            class="relative w-9 h-9 overflow-hidden rounded-full flex items-center justify-center"
          >
            <img
              v-if="authStore?.profileInformation.profilePicture"
              loading="lazy"
              :src="profilePictureUrl"
              alt="User profile picture"
              class="w-full h-full object-cover"
            />
            <img
              v-else
              loading="lazy"
              src="../../assets/onboarding/avatar.png"
              alt="User profile picture"
              class="w-full h-full object-cover"
            />
            <div class="absolute inset-0 bg-black/30"></div>
          </div>
          <div class="flex flex-col self-stretch my-auto">
            <div class="text-sm leading-none text-gray-700">
              {{ authStore?.firstName }} {{ authStore?.lastName }}
            </div>
            <div class="text-xs leading-none text-gray-500">
              {{ authStore?.email }}
            </div>
          </div>
        </div>
        <div
          class="self-start text-xs font-black leading-none text-violet-600"
        ></div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import {
  HOME_ICON,
  MUSICAL_NOTES_ICON,
  TRIANGLE_ERROR_ICON,
} from "@/assets/svg/shared/svgConstants";
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "OnboardingNavbar",
  props: {
    authStore: Object,
  },
  setup(props) {
    const profilePictureUrl = computed(() => {
      return `${
        props?.authStore?.profileInformation.profilePicture
      }?t=${new Date().getTime()}`;
    });

    return {
      HOME_ICON,
      MUSICAL_NOTES_ICON,
      TRIANGLE_ERROR_ICON,
      profilePictureUrl,
    };
  },
});
</script>
