<template>
  <div>
    <div class="flex flex-wrap gap-3 mt-2">
      <PasswordStrengthIndicator
        :is-valid="hasUpperCase"
        text="Upper case letter"
      />
      <PasswordStrengthIndicator
        :is-valid="hasLowerCase"
        text="Lower case letter"
      />
    </div>
    <div class="flex flex-wrap gap-3 mt-2">
      <PasswordStrengthIndicator
        :is-valid="hasMinLength"
        text="Min 8 character"
      />
      <PasswordStrengthIndicator
        :is-valid="passwordMatches"
        text="Password match"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import PasswordStrengthIndicator from "./PasswordStrengthIndicator.vue";

export default defineComponent({
  name: "PasswordStrength",
  components: {
    PasswordStrengthIndicator,
  },
  props: {
    password: {
      type: String,
      required: true,
    },
    confirmPassword: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const hasUpperCase = computed(() => /[A-Z]/.test(props.password));
    const hasLowerCase = computed(() => /[a-z]/.test(props.password));
    const hasMinLength = computed(() => props.password.length >= 8);
    const passwordMatches = computed(() => {
      return (
        props.password.length > 0 && props.password === props.confirmPassword
      );
    });

    return {
      hasUpperCase,
      hasLowerCase,
      hasMinLength,
      passwordMatches,
    };
  },
});
</script>
