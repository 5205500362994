import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 0,
  class: "text-red-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    for: _ctx.forAttr,
    class: _normalizeClass(_ctx.labelClasses)
  }, [
    _createTextVNode(_toDisplayString(_ctx.text) + " ", 1),
    (_ctx.required)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}