<template>
  <div class="flex overflow-hidden flex-col bg-white">
    <div
      v-if="loading"
      class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
    >
      <!-- Spinner -->
      <div
        class="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple"
      ></div>
    </div>
    <header class="flex flex-col w-full max-md:max-w-full">
      <OnboardingNavbar :auth-store="authStore" />
    </header>

    <main
      class="flex z-10 flex-col items-center pt-20 mt-0 w-full bg-white rounded-md shadow-[0px_-17px_16px_rgba(0,0,0,0.1)] max-md:max-w-full"
    >
      <section class="flex flex-col justify-center max-w-full text-center">
        <h1
          class="self-center text-3xl font-semibold leading-tight text-zinc-800 max-md:max-w-full"
        >
          <p v-if="authStore.invitedProgram">
            You have been invited to {{ authStore.invitedProgram?.name }}
          </p>
          <p v-else>Welcome to SlashExperts</p>
        </h1>
        <p class="mt-3 text-base text-neutral-700 max-md:max-w-full">
          Fill in the information to get started
        </p>
      </section>
      <section>
        <div
          class="flex flex-col mt-12 sm:flex-row items-center justify-center gap-4 sm:gap-2 md:gap-3 w-full"
        >
          <!-- First Block -->
          <div class="relative flex items-center space-x-2">
            <!-- Circle with Check Icon Centered -->
            <div
              class="w-5 h-5 bg-primary-purple rounded-full flex items-center justify-center"
            >
              <!-- Font Awesome Check Icon -->
              <i class="fas fa-check text-white text-xs"></i>
            </div>
            <div class="ml-2 text-center whitespace-nowrap">
              Profile Information
            </div>
          </div>

          <!-- First Line -->
          <div class="hidden sm:block w-16 h-px bg-gray-300"></div>

          <!-- Second Block -->
          <div class="flex items-center space-x-2">
            <div class="flex-shrink-0 w-2 h-2 bg-violet-600 rounded-full"></div>
            <div class="text-center whitespace-nowrap text-violet-600">
              Software Expertise
            </div>
          </div>

          <!-- Second Line -->
          <div class="hidden sm:block w-16 h-px bg-gray-300"></div>

          <!-- Third Block -->
          <div class="flex items-center space-x-2">
            <div class="flex-shrink-0 w-2 h-2 bg-gray-300 rounded-full"></div>
            <div class="text-center whitespace-nowrap">Your Calendar</div>
          </div>
        </div>
      </section>
      <div
        v-if="errorMessage"
        role="alert"
        class="flex gap-2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
      >
        <!-- Icon Container -->
        <div
          class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
        >
          <!-- Custom Image Icon -->
          <img
            src="../../assets/shared/failure.svg"
            alt="Error Icon"
            class="w-4 h-4"
          />
        </div>
        <p>{{ errorMessage }}</p>
      </div>
      <section class="flex flex-col mt-10 w-1/2">
        <div class="flex flex-col w-full max-md:max-w-full">
          <div class="flex flex-col max-w-full text-left">
            <h2 class="text-base font-medium text-gray-900">Other Softwares</h2>
            <p class="text-sm leading-loose text-gray-500">
              Add other software that you think you are expert of
            </p>
          </div>
          <!-- Search Input -->
          <div class="relative">
            <input
              v-model="searchQuery"
              type="text"
              placeholder="Search for software..."
              @focus="isFocused = true"
              @blur="handleBlur"
              class="overflow-hidden self-stretch px-3.5 py-2.5 mt-1 w-full text-sm leading-none whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm text-zinc-800 max-md:max-w-full"
            />

            <!-- Display Matching Names or No Record Found -->
            <ul
              v-if="isFocused && searchQuery.length > 0 && !isLoading"
              class="absolute top-full bg-white border border-gray-300 rounded-md shadow-sm z-10 max-h-60 overflow-auto w-full"
            >
              <li
                v-for="(card, index) in limitedResults"
                :key="index"
                @click="selectCard(card)"
                class="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100 text-left"
              >
                <img
                  :src="cardLogo(card)"
                  alt="Logo"
                  class="w-8 h-8 mr-2 rounded-full"
                />
                {{ card.name }}
              </li>
              <li
                v-if="limitedResults.length === 0 && !isLoading"
                class="px-4 py-2 text-gray-500"
              >
                <div class="flex-1 mb-1">
                  <p class="text-left">
                    Software you are looking for is not found
                  </p>
                </div>
                <div class="flex justify-center">
                  <BaseButton
                    type="button"
                    @click="showModal = true"
                    class="border border-gray-300 px-4 py-2 text-base text-gray-700 rounded-md"
                  >
                    + Add New Software
                  </BaseButton>
                </div>
              </li>
            </ul>
            <AddProgramModal
              :isVisible="showModal"
              @update:isVisible="showModal = $event"
              @programAdded="handleProgramAdded"
            />
          </div>
        </div>
        <div
          class="relative flex flex-wrap gap-4 px-2 pt-2 pb-8 w-full text-xs leading-none text-gray-500 bg-gray-50 rounded-md min-h-14 sm:min-h-36 md:min-h-44 lg:min-h-52 overflow-auto"
        >
          <div v-for="(card, index) in addedCards" :key="index">
            <SoftwareCard
              :id="card.id"
              :name="card.name"
              :experts="card.experts"
              :website="card.website"
              :logo="cardLogo(card)"
              isAdded
              @remove-card="handleRemoveCard"
            />
          </div>

          <!-- Text at the bottom right -->
          <div class="absolute bottom-2 right-2 text-gray-500 mt-4">
            Add minimum 3 Softwares
          </div>
        </div>
      </section>
      <div class="flex justify-start w-1/2">
        <section class="flex flex-col mt-16 max-w-full max-md:mt-10 text-left">
          <div class="flex flex-col max-w-full">
            <h2 class="flex-1 text-base font-medium text-gray-900">
              Suggested Softwares:
            </h2>
          </div>
          <div
            class="flex flex-col mt-5 w-full text-sm leading-none rounded-none max-md:w-full"
          >
            <div class="flex flex-wrap gap-4">
              <SoftwareCard
                v-for="card in topCards"
                :key="card.id"
                :id="card.id"
                :name="card.name"
                :experts="card.experts"
                :website="card.website"
                :logo="cardLogo(card)"
                @add-card="handleAddCard"
                class="flex-shrink-0 w-full sm:w-1/2 md:w-1/2 lg:w-1/3"
              />
            </div>
          </div>
        </section>
      </div>
    </main>
    <footer
      class="flex flex-wrap gap-7 justify-center mt-14 items-center self-stretch py-4 w-full text-base font-medium whitespace-nowrap border-t border-gray-300 border-solid max-md:max-w-full"
    >
      <div class="flex w-1/2 gap-4 justify-center items-center">
        <button
          class="flex overflow-hidden w-1/5 gap-3 justify-center items-center self-stretch py-2.5 pr-4 pl-4 my-auto text-indigo-700 bg-indigo-50 rounded-md shadow-sm"
          @click="goToProfileInformation"
          :disabled="isDisabled"
        >
          <span class="self-stretch my-auto">
            <i class="fas fa-arrow-left shrink-0 w-5 aspect-square"></i>
            Back
          </span>
        </button>
        <BaseButton
          type="button"
          class="flex overflow-hidden w-4/5 flex-wrap gap-3 justify-center items-center self-stretch py-2.5 pr-4 pl-4 my-auto text-white bg-violet-600 rounded-md shadow-sm max-md:max-w-full"
          @click="applyToPrograms"
          :disabled="isDisabled"
        >
          <span v-if="!isDisabled" class="self-stretch my-auto"
            >Next
            <i class="fas fa-arrow-right shrink-0 w-5 aspect-square"></i>
          </span>
          <span v-else class="self-stretch my-auto">
            Submitting details.....</span
          >
        </BaseButton>
      </div>
    </footer>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
  watch,
} from "vue";
import SoftwareCard from "../../components/onboarding/SoftwareCard.vue";
import { Card } from "../../types/onboarding/types";
import debounce from "lodash/debounce";
import BaseButton from "../../components/shared/BaseButton.vue";
import OnboardingNavbar from "@/components/onboarding/OnboardingNavbar.vue";
import AddProgramModal from "@/components/onboarding/AddProgramModal.vue";
import { ApolloError, gql } from "@apollo/client/core";
import { useMutation, useQuery } from "@vue/apollo-composable";
import router from "@/router";
import { useAuthStore } from "@/stores/auth";
import { PostHog } from "posthog-js";

const GET_PROGRAM = gql`
  query GetProgram($userBased: Boolean!) {
    getProgram(userBased: $userBased) {
      programs {
        id
        name
        website
        logo
        experts
      }
    }
  }
`;

const MODIFY_USER_LINKS = gql`
  mutation createProgram($input: CreateProgramInput!) {
    createProgram(input: $input) {
      programs {
        id
        name
        website
      }
      errors
    }
  }
`;

export default defineComponent({
  name: "SoftwareExpertise",
  components: {
    SoftwareCard,
    BaseButton,
    AddProgramModal,
    OnboardingNavbar,
  },
  setup() {
    const initialCards = ref<Card[]>([]);
    const searchQuery = ref("");
    const isFocused = ref(false);

    const addedCards = ref<Card[]>([]);
    const selectedPrograms = ref<string[]>([]);
    const limit = 10;
    const filteredNames = ref<Card[]>([]);
    const showModal = ref(false);
    const isLoading = ref(false);
    const result = ref<any>(null);
    const loading = ref(true);
    const errorMessage = ref("");
    const invited = ref("");
    const isDisabled = ref(false);
    const userCreatedSoftwareCards = ref<Card[]>([]);
    const softwaresAdded = ref(0);
    const posthog = (getCurrentInstance()?.proxy as { $posthog: PostHog })
      ?.$posthog;

    const authStore = useAuthStore();

    onMounted(() => {
      fetchProgramData();
    });

    const {
      mutate: createProgram,
      onDone: createProgramDone,
      onError: createProgramError,
    } = useMutation(MODIFY_USER_LINKS);

    const applyToPrograms = () => {
      isDisabled.value = true;
      if (selectedPrograms.value.length < 3) {
        errorMessage.value = "Please select at least 3 softwares.";
        isDisabled.value = false;
        scrollToTop();
        return;
      }
      errorMessage.value = "";

      isDisabled.value = false;
      const addedCardIds = new Set(addedCards.value.map((card) => card.id));

      const payload = addedCards.value.map((addedCard) => ({
        id: addedCard.id,
        website: addedCard.website,
        name: addedCard.name,
        destroy: false,
      }));

      const presentToAdd = authStore.programsLinked
        .filter((card) => !addedCardIds.has(card.id))
        .map((card) => ({
          id: card.id,
          website: card.website,
          name: card.name,
          destroy: true,
        }));

      const finalPayload = [...payload, ...presentToAdd];
      softwaresAdded.value = finalPayload.length;
      createProgram({
        input: {
          programParams: finalPayload,
          bulk: true,
        },
      });
    };

    createProgramDone((response) => {
      isDisabled.value = false;
      if (response) {
        const errors = response.data.createProgram.errors;
        if (errors.length > 0) {
          errorMessage.value = errors.join(". ");
          console.log("errorMessage from here");
          scrollToTop();
        } else {
          authStore.setProgramsLinked(response.data.createProgram.programs);
          let newlyCreatedCards = userCreatedSoftwareCards.value;
          newlyCreatedCards.push(...authStore.userCreatedSoftwareCards);
          authStore.setSoftwareExpertise(selectedPrograms.value);
          authStore.setUserCreatedSoftwareCards(newlyCreatedCards);
          if (posthog) {
            posthog.capture("expert_software_expertise_completed", {
              email: authStore.email,
              softwares_added: softwaresAdded.value,
            });
          }
          router.push({ name: "OnboardingCalendar" });
        }
      }
    });

    createProgramError((mutationError: ApolloError) => {
      errorMessage.value = mutationError.message;
      scrollToTop();
      isDisabled.value = false;
    });

    const handleInvitedProgram = () => {
      if (authStore.invitedProgram) {
        const invitedProgramId = authStore.invitedProgram.id;
        const containsId = authStore.softwareExpertise.some(
          (id) => id === invitedProgramId
        );

        if (!containsId) {
          const invitedCard = initialCards.value.find(
            (card) => card.id === invitedProgramId
          );

          if (invitedCard) {
            selectCard(invitedCard);
          }
        }
      }
    };

    const selectCard = (card: Card) => {
      errorMessage.value = "";
      if (!addedCards.value.some((addedCard) => addedCard.name === card.name)) {
        addedCards.value.push(card);
        selectedPrograms.value.push(card.id);
        searchQuery.value = "";
        authStore.softwareExpertise.push(card.id);
      } else {
        errorMessage.value = "This software is already added";
        scrollToTop();
      }
    };

    const handleAddCard = (card: Card) => {
      errorMessage.value = "";
      const cardExists = addedCards.value.some(
        (existingCard) => existingCard.name === card.name
      );

      if (cardExists) {
        errorMessage.value = "This software is already added";
        scrollToTop();
      } else {
        addedCards.value.push(card);
        selectedPrograms.value.push(card.id);
        authStore.softwareExpertise.push(card.id);
      }
    };

    const handleRemoveCard = (card: Card) => {
      addedCards.value = addedCards.value.filter(
        (addedCard) => addedCard.name !== card.name
      );
      selectedPrograms.value = selectedPrograms.value.filter(
        (selectedProgram) => selectedProgram !== card.id
      );
      authStore.softwareExpertise = authStore.softwareExpertise.filter(
        (softwareExpertise) => softwareExpertise !== card.id
      );
    };

    const handleProgramAdded = (newProgram: any) => {
      searchQuery.value = "";
      initialCards.value.push(newProgram);
      addedCards.value.push(newProgram);
      selectedPrograms.value.push(newProgram.id);
      userCreatedSoftwareCards.value.push(newProgram);
      authStore.softwareExpertise.push(newProgram.id);
    };

    const handleBlur = () => {
      setTimeout(() => {
        isFocused.value = false;
      }, 300);
    };

    const goToProfileInformation = () => {
      router.push({ name: "OnboardingProfileInformation" });
    };

    const cardLogo = (card: Card) => {
      return card.logo ? card.logo : logoFromWebsite(card.website);
    };

    const logoFromWebsite = (websiteUrl: string): string => {
      const urlTemplate =
        "https://img.logo.dev/{domain}?token=pk_R1LW6BZBRPKs6t3nZphCOw";
      const placeholder = "{domain}";

      try {
        const url = new URL(websiteUrl);
        const domain = url.hostname.split(".").slice(-2).join(".");

        return urlTemplate.replace(placeholder, domain);
      } catch (error) {
        return urlTemplate.replace(placeholder, "www.logo.com");
      }
    };

    const performSearch = debounce(() => {
      filteredNames.value = initialCards.value
        .filter((card) =>
          card.name.toLowerCase().includes(searchQuery.value.toLowerCase())
        )
        .slice(0, limit);
      isLoading.value = false;
    }, 100);

    watch(searchQuery, () => {
      isLoading.value = true;
      performSearch();
    });

    const fetchProgramData = () => {
      const {
        result: queryResult,
        loading: queryLoading,
        error: queryError,
      } = useQuery(GET_PROGRAM, {
        userBased: false,
      });

      watch(
        () => queryResult.value,
        (newValue) => {
          result.value = newValue;
          if (result.value) {
            initialCards.value = [...result.value.getProgram.programs];

            const expertiseIds = new Set(authStore.softwareExpertise);
            const userAddedSoftwareCards = authStore.userCreatedSoftwareCards;

            const filteredSelectedCards = initialCards.value.filter((card) =>
              expertiseIds.has(card.id)
            );

            const filteredNewlyCreatedCards = userAddedSoftwareCards.filter(
              (card) => expertiseIds.has(card.id)
            );

            addedCards.value = [...filteredSelectedCards];
            const addedCardIds = new Set(
              addedCards.value.map((card) => card.id)
            );

            const filteredNotInAddedCards = filteredNewlyCreatedCards.filter(
              (card) => !addedCardIds.has(card.id)
            );

            selectedPrograms.value = [...expertiseIds];
            initialCards.value.push(...userAddedSoftwareCards);
            addedCards.value.push(...filteredNotInAddedCards);
            handleInvitedProgram();
          }
        },
        { immediate: true }
      );

      watch(
        () => queryLoading.value,
        (newLoading) => {
          loading.value = newLoading;
        },
        { immediate: true }
      );

      watch(
        () => queryError.value,
        (newError) => {
          if (newError) {
            errorMessage.value =
              "Failed to fetch programs list, please refresh to try again.";
            console.error("Query error:", newError);
          }
        }
      );
    };

    const scrollToTop = () => {
      const c = document.documentElement.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
      }
    };

    const topCards = computed(() => {
      return [...initialCards.value]
        .filter(
          (program) =>
            program.id !== authStore.invitedProgram?.id &&
            !addedCards.value.some((addedCard) => addedCard.id === program.id)
        )
        .sort((a, b) => b.experts - a.experts)
        .slice(0, 10);
    });

    const profilePictureUrl = computed(() => {
      return `${
        authStore.profileInformation.profilePicture
      }?t=${new Date().getTime()}`;
    });

    return {
      addedCards,
      handleAddCard,
      handleRemoveCard,
      searchQuery,
      filteredNames,
      selectCard,
      isFocused,
      handleBlur,
      performSearch,
      limitedResults: filteredNames,
      showModal,
      isLoading,
      handleProgramAdded,
      applyToPrograms,
      cardLogo,
      topCards,
      errorMessage,
      invited,
      goToProfileInformation,
      isDisabled,
      authStore,
      profilePictureUrl,
      loading,
    };
  },
});
</script>
