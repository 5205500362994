<template>
  <div></div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";

export default defineComponent({
  name: "InviteExpertView",
  setup() {
    const route = useRoute();

    onMounted(() => {
      if (route.query.new === "false") {
        router.push({
          name: "SignIn",
          query: { token: route.query.token },
        });
      } else if (route.query.new === "true") {
        router.push({ name: "SignUp", query: { token: route.query.token } });
      }
    });
  },
});
</script>
