<template>
  <main class="flex flex-col min-h-screen bg-gray-50">
    <section
      class="flex-grow relative flex flex-col justify-center items-center px-16 py-20 w-full max-md:px-5 max-md:max-w-full"
    >
      <img
        loading="lazy"
        src="../assets/shared/background.png"
        alt=""
        class="absolute inset-0 w-full h-full object-cover"
      />
      <div class="flex relative flex-col mb-5 max-w-full">
        <img
          loading="lazy"
          src="../assets/shared/experts-text.png"
          alt="Company logo"
          class="self-center max-w-full aspect-[4]"
        />
        <div
          class="flex flex-col justify-center px-6 py-8 mt-20 bg-white rounded-md border border-solid shadow-sm max-md:px-5 max-md:mt-10 max-md:max-w-full"
        >
          <header class="flex flex-col self-center w-96 max-w-full text-center">
            <h1 class="text-3xl font-semibold leading-tight text-zinc-800">
              Sign Up
            </h1>
            <p class="mt-3 text-base text-neutral-700">
              How you want to sign up in Slashexpert?
            </p>
          </header>
          <fieldset class="flex flex-col w-full">
            <div
              class="flex justify-between items-center py-4 pr-2 pl-5 mt-6 w-full bg-white rounded-xl border-solid border-[1.5px] border-neutral-200"
            >
              <div class="flex gap-3 items-start">
                <div v-html="PERSON_ICON"></div>
                <div class="flex flex-col text-left">
                  <span
                    class="text-base font-medium tracking-wide leading-none text-neutral-900"
                  >
                    As Expert
                  </span>
                  <span
                    class="mt-1 text-sm tracking-wide leading-5 text-neutral-500"
                  >
                    Rorem ipsum dolor sit consectetur<br />
                    consectetur adipiscing elit.
                  </span>
                </div>
              </div>
              <BaseButton
                type="button"
                class="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center"
                @click="goToExpertSignUp"
              >
                <div
                  v-html="ARROW_RIGHT_ICON"
                  class="text-primary-purple"
                ></div>
              </BaseButton>
            </div>
            <div
              class="flex justify-between items-center py-4 pr-2 pl-5 mt-6 w-full bg-white rounded-xl border-solid border-[1.5px] border-neutral-200"
            >
              <div class="flex gap-3 items-start">
                <div v-html="PEOPLE_ICON"></div>
                <div class="flex flex-col text-left">
                  <span
                    class="text-base font-medium tracking-wide leading-none text-neutral-900"
                  >
                    As Company
                  </span>
                  <span
                    class="mt-1 text-sm tracking-wide leading-5 text-neutral-500"
                  >
                    Rorem ipsum dolor sit consectetur<br />
                    consectetur adipiscing elit.
                  </span>
                </div>
              </div>

              <!-- Arrow icon at the end of the row -->
              <BaseButton
                type="button"
                class="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center"
                @click="goToCompanySignUp"
              >
                <div
                  v-html="ARROW_RIGHT_ICON"
                  class="text-primary-purple"
                ></div>
              </BaseButton>
            </div>
          </fieldset>
          <BaseButton
            type="button"
            class="flex overflow-hidden gap-3 justify-center items-center py-2.5 pr-4 pl-4 mt-6 max-w-full text-base font-medium text-primary-purple whitespace-nowrap rounded-md shadow-sm"
            @click="goToSignIn"
          >
            <div v-html="ARROW_LEFT_ICON"></div>
            <span class="self-stretch my-auto">Back</span>
          </BaseButton>
        </div>
      </div>
    </section>
  </main>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  PERSON_ICON,
  PEOPLE_ICON,
  ARROW_RIGHT_ICON,
  ARROW_LEFT_ICON,
} from "@/assets/svg/shared/svgConstants";
import { useRouter } from "vue-router";
import BaseButton from "@/components/shared/BaseButton.vue";

export default defineComponent({
  name: "SignUpSelect",
  components: {
    BaseButton,
  },
  setup() {
    const router = useRouter();

    const goToSignIn = () => {
      router.push({ name: "SignIn" });
    };

    const goToExpertSignUp = () => {
      router.push({ name: "SignUp" });
    };

    const goToCompanySignUp = () => {
      window.open(process.env.VUE_APP_COMPANY_SIGN_UP, "_self");
    };

    return {
      PERSON_ICON,
      PEOPLE_ICON,
      ARROW_RIGHT_ICON,
      ARROW_LEFT_ICON,
      goToSignIn,
      goToExpertSignUp,
      goToCompanySignUp,
    };
  },
});
</script>
