import { defineStore } from "pinia";
import { ProfileInformation } from "../types/interfaces";
import { Card } from "@/types/onboarding/types";
import { invitedProgram } from "@/types/shared";
interface AuthState {
  token: string | null;
  // userType: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  calendarSynced: string | null;
  profileInformation: ProfileInformation;
  softwareExpertise: string[];
  userCreatedSoftwareCards: Card[];
  programsLinked: Card[];
  invitedProgram: invitedProgram | null;
}

export const useAuthStore = defineStore("auth", {
  state: (): AuthState => ({
    token: null,
    // userType: null,
    email: null,
    firstName: null,
    lastName: null,
    calendarSynced: null,
    profileInformation: {
      linkedinUrl: "",
      country: "",
      state: "",
      industry: "",
      profilePicture: "",
      about: "",
    },
    softwareExpertise: [],
    userCreatedSoftwareCards: [],
    programsLinked: [],
    invitedProgram: null,
  }),
  actions: {
    setToken(newToken: string) {
      this.token = newToken;
    },
    // setUserType(newUserType: string) {
    //   this.userType = newUserType;
    // },
    setEmail(newEmail: string) {
      this.email = newEmail;
    },
    setFirstName(newFirstName: string) {
      this.firstName = newFirstName;
    },
    setLastName(newLastName: string) {
      this.lastName = newLastName;
    },
    setCalendarSynced(newCalendarSynced: string) {
      this.calendarSynced = newCalendarSynced;
    },
    setProfileInformation(newProfileInformation: Partial<ProfileInformation>) {
      this.profileInformation = {
        ...this.profileInformation,
        ...newProfileInformation,
      };
    },
    setSoftwareExpertise(newSoftwareExpertise: string[]) {
      this.softwareExpertise = newSoftwareExpertise;
    },
    setUserCreatedSoftwareCards(newUserCreatedSoftwareCards: Card[]) {
      this.userCreatedSoftwareCards = newUserCreatedSoftwareCards;
    },
    setProgramsLinked(newProgramsLinked: Card[]) {
      this.programsLinked = newProgramsLinked;
    },
    setInvitedProgram(newInvitedProgram: invitedProgram) {
      this.invitedProgram = newInvitedProgram;
    },
    clearInvitedProgram() {
      this.invitedProgram = null;
    },
    resetState() {
      Object.assign(this, {
        token: null,
        // userType: null,
        email: null,
        firstName: null,
        lastName: null,
        calendarSynced: null,
        profileInformation: {
          linkedinUrl: "",
          country: "",
          state: "",
          industry: "",
          profilePicture: "",
          about: "",
        },
        softwareExpertise: [],
        userCreatedSoftwareCards: [],
        programsLinked: [],
        invitedProgram: null,
      });
    },
  },
  persist: true,
});
