<template>
  <div class="flex flex-col mt-7 w-full max-md:mt-10">
    <div class="flex gap-3 self-start">
      <span class="grow text-lg font-light leading-loose text-violet-600">
        <div v-html="CIRCLE_EXCLAMATION"></div>
      </span>
      <h2 class="my-auto text-sm font-medium leading-none text-zinc-800">
        About Program
      </h2>
    </div>
    <p
      class="mt-3 mr-6 text-sm leading-5 text-gray-500 text-left max-md:mr-2.5"
    >
      {{ selectedProgram?.description }}
    </p>
    <hr class="shrink-0 mt-7 max-w-full h-px border border-solid" />
    <div class="flex gap-3 self-start mt-6">
      <span class="grow text-lg font-light leading-loose text-primary-purple">
        <div v-html="EARNINGS_ICON_CALIBRI" class="text-primary-purple"></div>
      </span>
      <h3 class="my-auto text-sm font-medium leading-none text-zinc-800">
        Pricing & Rates
      </h3>
    </div>
    <div
      class="flex overflow-hidden justify-center items-center self-start mt-2 text-sm font-medium tracking-normal leading-5 text-center whitespace-nowrap rounded-lg border border-solid border-zinc-500 text-zinc-900"
    >
      <span class="gap-2 self-stretch px-4 py-1.5 my-auto"
        >${{ selectedProgram?.payout }}/meeting</span
      >
    </div>
    <hr class="shrink-0 mt-6 max-w-full h-px border border-solid" />
  </div>
</template>

<script lang="ts">
import {
  CIRCLE_EXCLAMATION,
  EARNINGS_ICON_CALIBRI,
} from "@/assets/svg/expert-overview/svgConstants";
import { defineComponent, PropType } from "vue";
import { Program } from "@/types/dashboard/types";

export default defineComponent({
  name: "AboutProgram",
  props: {
    selectedProgram: {
      type: Object as PropType<Program | null>,
      default: null,
    },
  },
  setup() {
    return {
      CIRCLE_EXCLAMATION,
      EARNINGS_ICON_CALIBRI,
    };
  },
});
</script>
