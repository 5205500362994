import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/stylesheets/tailwind.css";
import { createPinia } from "pinia";
import posthogPlugin from "../plugins/posthog";
import piniaPersistedState from "pinia-plugin-persistedstate";
import "./assets/stylesheets/global.css";
import { setupApolloClient } from "./apollo";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";

Bugsnag.start({
  apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginVue()],
});

const bugsnagVue = Bugsnag.getPlugin("vue");
const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPersistedState);

app.use(pinia);
app.use(router);
app.use(posthogPlugin);
if (bugsnagVue) app.use(bugsnagVue);
setupApolloClient();
app.mount("#app");
